<template>
    <div class="pay-select">
        <div class="pay-content">
            <div class="order-info">
                <div class="title">
                    <i class="el-icon-success"></i>
                    <span>提交成功,请尽快付款</span>
                </div>

                <div class="content">
                    <p>{{ getDesc }}</p>
                    <p>
                        订单编号: <span>{{ code }}</span>
                    </p>
                </div>
            </div>
            <header class="common-header">
                <div class="title pad-left">
                    <span>请选择支付方式：</span>
                </div>
            </header>

            <div class="select-pay">
                <div class="item-pay" :class="selctIndex == 3 ? 'active' : ''" @click="handleSelect(3)">
                    <img class="we-pay" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646358283637.png" alt="" />
                    <span>微信支付</span>
                </div>
                <div class="item-pay" :class="selctIndex == 1 ? 'active': ''" @click="handleSelect(1)">
                    <img class="we-pay" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646358293951.png" alt="">
                    <span>支付宝</span>
                </div>
                <!-- <div class="item-pay" :class="selctIndex == 3 ? 'active': ''" @click="handleSelect(3)">
                    <img class="we-bank" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646358308763.png" alt="">
                    <span>银联</span>
                </div> -->
                <div class="item-pay" :class="selctIndex == 2 ? 'active' : ''" @click="handleSelect(2)">
                    <img class="we-offline" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646358321422.png" alt="" />
                    <span>线下对公转账</span>
                </div>
            </div>

            <div class="button-list">
                <button class="back" @click="handleBack(3)">返回</button>
                <button class="submit" @click="handleSubmit" v-if="pay_state == 0">提交</button>
            </div>
        </div>
        <!-- 支付成功 -->
        <el-dialog :visible.sync="show" width="400px" append-to-body :show-close="false" :close-on-click-modal="false">
            <div class="dialog-content">
                <header class="dialog-header">
                    <span></span>
                    <!-- <img class="del" @click="show = false" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646295309622.png" alt=""> -->
                </header>
                <div class="qrcode">
                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646363795404.jpg" alt="" />
                    <div class="success-status">
                        <i class="el-icon-success"></i>
                        <span>提交成功</span>
                    </div>
                </div>
                <p class="tips">尊敬的客户您好，您的订单已完成，谢谢。</p>
                <div class="follow-wechat">您可关注“广福保”微信公众号查看您的保单信息。</div>
                <div class="dialog-button-list">
                    <button class="back" @click="handleBack(1)">返回首页</button>
                    <button class="submit" @click="handleBack(2)">订单查询</button>
                </div>
            </div>
        </el-dialog>

        <!-- 线下对公转账 -->
        <el-dialog :visible.sync="onlineFlag" width="400px" append-to-body :show-close="false" :close-on-click-modal="false">
            <div class="dialog-content-online">
                <header class="dialog-header">
                    <span></span>
                    <img class="del" @click="handleClose" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646295309622.png" alt="" />
                </header>
                <div class="online-list">
                    <div class="online-item">
                        <span class="label">银行账户</span>
                        <span class="text">平安银行广州中山四路支行</span>
                    </div>
                    <div class="online-item">
                        <span class="label">账户名称</span>
                        <span class="text">向日葵保险经纪有限公司</span>
                    </div>
                    <div class="online-item">
                        <span class="label">账号</span>
                        <span class="text">19014528305311</span>
                    </div>
                    <div class="online-item">
                        <span class="label">行号</span>
                        <span class="text">307581009089</span>
                    </div>
                </div>
                <div class="button-list">
                    <button class="copy" @click="handleCopy">复制</button>
                </div>

                <footer class="online-desc">
                    <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646291498169.png" alt="" />
                    <div>
                        注：线下转账成功后请登录订单查询在待支付中点击“上传支付凭证”按钮上传支付凭证
                    </div>
                </footer>
            </div>
        </el-dialog>
        <textarea class="copy-style" :value="copyText" id="copyText" cols="30" rows="10" readonly></textarea>

        <!-- 微信支付弹框 -->
        <el-dialog v-if="payFlag" :visible.sync="payFlag" width="400px" append-to-body :show-close="false">
            <div class="dialog-content">
                <header class="dialog-header">
                    <span></span>
                    <img class="del" @click="payFlag = false" src="https://oss.nuohuipay.cn/ClientProject/nuohuifu/images/1646295309622.png" alt="" />
                </header>
                <div class="qrcode">
                    <div class="qrcode-img" ref="qrcode"></div>
                    <!-- <img src="https://oss.nuohuipay.cn/ClientProject/guangfubao/images/1646363795404.jpg" alt=""> -->
                    <div class="success-status">
                        <span>{{selctIndex == 1 ? '请用支付宝扫描二维码支付' : selctIndex == 3 ? '请用微信扫描二维码支付' : ''}}</span>
                    </div>
                </div>
                <!-- <p class="tips">尊敬的客户您好，您的订单已完成，谢谢。</p> -->
                <div class="follow-wechat">
                    支付成功后，您可关注“广福保”微信公众号查看您的保单信息。
                </div>
                <div class="dialog-button-list">
                    <button class="back" @click="payFlag = false">取消</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "../../assets/api/apiurl";
import QRCode from "qrcodejs2";

export default {
    data() {
        return {
            copyText: `
                银行账户：平安银行广州中山四路支行 \n
                账户名称：向日葵保险经纪有限公司  \n
                账号：19014528305311 \n
                行号：307581009089
            `,
            show: false, // 支付成功
            onlineFlag: false, // 线下对公转账
            selctIndex: -1,
            payFlag: false,
            pay_url: "",
            insured_end: "",
            code: "",
            pay_state: -100,
            timer: null
        };
    },
    computed: {
        getDesc() {
            return this.insured_end
                ? `团体投保缴费截止时间为${this.insured_end}，否则订单会自动取消`
                : "";
        },
    },
    mounted() {
        this.getOrderDetail();
    },
    methods: {
        handleClose() {
            this.onlineFlag = false;
            // this.$router.replace("/");
        },
        handleSelect(index) {
            if (this.pay_state != 0) {
                return false;
            }
            if (index == 2) {
                this.onlineFlag = true
            }
            this.selctIndex = index;
        },
        getOrderDetail() {
            this.$ajax
                .get(api.API_URL + "/user/order_info", {
                    params: {
                        id: this.$route.query.id,
                    },
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        this.insured_end = res.data.data.pay_end_time;
                        this.code = res.data.data.number;
                        this.pay_state = res.data.data.pay_state;
                    }
                });
        },
        handleBack(index) {
            if (index == 1) {
                let query = {}
                query['pid'] = localStorage.getItem('pid') || ''
                query['cid'] = localStorage.getItem('cid') || ''
                query['smid'] = localStorage.getItem('smid') || ''
                this.$router.push({
                    path: '/home',
                    query: {
                        ...query
                    }
                });
            } else if (index == 2) {
                this.$router.push("/order_list");
            } else {
                this.$router.go(-1);
            }
        },
        handleCopy() {
            document.getElementById("copyText").select();
            document.execCommand("copy");
            this.$message({
                message: "复制成功",
                type: "success",
            });
        },
        handleSubmit() {
            if (this.selctIndex == -1) {
                this.$message({
                    message: "请选择支付方式",
                    type: "warning",
                });
                return false;
            }
            this.getPayUrl();
        },
        getPayUrl() {
            let titles = (this.selctIndex == 1 || this.selctIndex == 3) ? '正在吊起支付中...' : '提交订单中...'
            const loading = this.$loading({
                lock: true,
                text: titles,
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let that = this;
            this.$ajax
                .post(api.API_URL + "/order/pay", {
                    id: this.$route.query.id,
                    pay_type: this.selctIndex,
                })
                .then((res) => {
                    console.log(res, 111)
                    loading.close();
                    if (res.data.code == 1) {
                        if (that.selctIndex == 2) {
                            // 线下
                            // that.onlineFlag = true;
                            that.show = true
                        } else if (that.selctIndex == 1 || that.selctIndex == 3) {
                            that.pay_url = res.data.data.pay_url;
                            that.payFlag = true;
                            that.$nextTick(() => {
                                var qrcode = new QRCode(that.$refs.qrcode, {
                                    text: that.pay_url, // 需要转换为二维码的内容
                                    width: 119,
                                    height: 119,
                                    colorDark: "#000000",
                                    colorLight: "#ffffff",
                                    correctLevel: QRCode.CorrectLevel.H,
                                });
                                clearInterval(that.timer);
                                that.timer = null;
                                that.queryOrderStatus();
                            });
                        }
                    }
                })
                .catch((err) => {
                    loading.close();
                });
        },
        // 轮询查询订单状态
        queryOrderStatus() {
            let count = 0;
            this.timer = setInterval(async () => {
                count++;

                if (count >= 1200) {
                    clearInterval(this.timer);
                    this.timer = null;
                    count = 0;
                }
                let res = await this.$ajax.get(api.API_URL + "/user/order_info", {
                    params: {
                        id: this.$route.query.id,
                    },
                });
                if (res.data.code == 1) {
                    if (res.data.data.pay_state == 1) {
                        this.payFlag = false;
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        this.getOrderDetail();
                    }
                }
            }, 3000);
            this.$once('hook:beforeDestroy', function () {
                clearInterval(this.timer)
                this.timer = null
            })
        },
    },
};
</script>

<style lang="less">
.el-icon-loading {
    color: #ffffff !important;
    font-size: 30px;
}
.el-loading-text {
    color: #ffffff !important;
}
</style>
<style lang="less" scoped>
.pad-left {
    padding-left: 30px;
}
.copy-style {
    position: fixed;
    left: -9999px;
    z-index: -1;
}
// 线下对公
.dialog-content-online {
    .dialog-header {
        padding: 20px 14px 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .del {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
    .online-list {
        padding: 5px 28px 0;
        .online-item:nth-child(1) {
            margin-top: 0;
        }
        .online-item {
            margin-top: 14px;
            font-size: 13px;
            display: flex;
            .label {
                width: 80px;
                font-weight: 400;
                color: #999999;
                text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
            }
            .text {
                font-weight: 600;
                color: #333333;
                text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .button-list {
        margin-top: 28px;
        display: flex;
        justify-content: center;
        .copy {
            cursor: pointer;
            width: 92px;
            height: 36px;
            box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            border: 1px solid #a72c1c;
            font-size: 13px;
            font-weight: 600;
            color: #a72c1c;
            text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
            background: #ffffff;
        }
    }

    .online-desc {
        border-top: 1px solid #e2e2e2;
        margin: 21px 14px 0;
        padding: 20px 0;
        display: flex;
        align-items: flex-start;
        img {
            width: 14px;
            height: 14px;
            position: relative;
            top: 3px;
        }
        div {
            flex: 1;
            margin-left: 10px;
            font-size: 10px;
            font-weight: 400;
            color: #a72c1c;
            text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
            line-height: 18px;
        }
    }
}

// 支付成功
.dialog-content {
    padding-bottom: 36px;
    .dialog-header {
        padding: 20px 14px 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .del {
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
    .qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 119px;
            height: 119px;
            background: #d8d8d8;
        }
        .qrcode-img {
            width: 125px;
            height: 125px;
            background: #ffffff;
            padding: 3px;
        }
        .success-status {
            margin-top: 14px;
            .el-icon-success {
                color: #a72c1c;
                font-size: 20px;
            }
            span {
                margin-left: 10px;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
            }
        }
    }
    .tips {
        margin-top: 16px;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
    }
    .follow-wechat {
        padding: 4px 3px;
        margin: 12px 25px 0;
        background: #f3cdcd;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        font-size: 13px;
        font-weight: 400;
        color: #a72c1c;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dialog-button-list {
        margin-top: 28px;
        display: flex;
        justify-content: center;
        .back {
            cursor: pointer;
            width: 128px;
            height: 36px;
            box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
            border-radius: 1px;
            border: 1px solid #a72c1c;
            background: #ffffff;
            font-size: 13px;
            font-weight: 600;
            color: #a72c1c;
            text-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
        }
        .submit {
            cursor: pointer;
            outline: none;
            margin-left: 20px;
            width: 128px;
            height: 36px;
            background: #a72c1c;
            border-radius: 1px;
            font-size: 13px;
            font-weight: 500;
            color: #ffffff;
            border: none;
        }
    }
}

.common-header {
    margin-left: 20px;
    padding: 28px 0 20px;
    .title {
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: flex-end;
        &::before {
            content: "";
            width: 5px;
            height: 16px;
            background: #a72c1c;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        span:nth-child(1) {
            color: #333333;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
        }
        span:nth-child(2) {
            overflow: hidden;
            font-size: 11px;
            line-height: 11px;
            font-weight: 400;
            color: #999999;
            &::before {
                content: "";
                width: 1px;
                border: 1px solid #999999;
                background: #999999;
                margin: 0 7px 0 11px;
                box-sizing: border-box;
            }
        }
    }
}
.pay-select {
    min-height: calc(100vh - 80px - 60px);
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    .pay-content {
        margin-top: 21px;
        min-width: 1200px;
        height: 506px;
        background: #ffffff;
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        .order-info {
            margin: 21px 21px 0;
            padding: 28px;
            border-radius: 3px;
            border: 1px solid #e2e2e2;
            .title {
                display: flex;
                align-items: center;
                span {
                    margin-left: 13px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333333;
                }
            }
            .content {
                margin-top: 20px;
                p {
                    font-size: 13px;
                    line-height: 28px;
                    font-weight: 400;
                    color: #333333;
                    span {
                        color: #999999;
                    }
                }
            }
        }
        .select-pay {
            padding: 0 50px;
            display: flex;
            .item-pay:nth-child(1) {
                margin-left: 0;
            }
            .item-pay.active {
                border-color: #a72c1c;
                background: #f3cdcd;
            }
            .item-pay {
                cursor: pointer;
                margin-left: 19px;
                width: 149px;
                height: 140px;
                background: #ffffff;
                border-radius: 3px;
                border: 1px solid #e2e2e2;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                transition: all 0.3s;
                .we-pay {
                    width: 40px;
                    height: 40px;
                }
                .we-bank {
                    width: 65px;
                    height: 40px;
                }
                .we-offline {
                    width: 40px;
                    height: 40px;
                }
                span {
                    margin-top: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
        .button-list {
            margin-top: 36px;
            display: flex;
            justify-content: center;
            .back {
                cursor: pointer;
                width: 92px;
                height: 36px;
                border-radius: 1px;
                border: 1px solid #e2e2e2;
                font-size: 13px;
                font-weight: 500;
                color: #666666;
                background: #ffffff;
            }
            .submit {
                cursor: pointer;
                outline: none;
                margin-left: 18px;
                width: 92px;
                height: 36px;
                background: #a72c1c;
                border-radius: 1px;
                font-size: 13px;
                font-weight: 500;
                color: #ffffff;
                border: none;
            }
        }
    }
}
.el-icon-success {
    color: #a72c1c;
    font-size: 21px;
}
</style>
